import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Field } from 'formik';
import { Box, Anchor, Heading, Text } from 'grommet';
import { Package } from 'grommet-icons';
import useMobile from '../useMobile';

import {
  STANDARD_PACKAGE_TYPE,
  STANDARD_PACKAGE_DIMENSIONS,
} from '../../lib/constants';
import InputPackageOrderReturnItem from './InputPackageOrderReturnItem';

const InputOrderReturnPackage = ({
  index,
  arrayHelpers,
  packageItems,
  onIncludedItemChange,
  field,
  form,
}) => {
  const isMobile = useMobile();
  const { value } = field;
  const { errors } = form;
  const isStandard = value.type === STANDARD_PACKAGE_TYPE;

  return (
    <Box fill>
      <Box pad="medium" fill gap="small">
        <Box direction="row-responsive" gap="small" align="center">
          <Box align="center" direction="row" gap="small">
            <Package color="black" />
            <Text size="large">Box #{index + 1}</Text>
            {index > 0 && (
              <Box margin={{ left: 'small' }}>
                <Anchor
                  size="small"
                  label="Remove"
                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          flex={true}
          gap="small"
          pad={isMobile ? 'none' : { left: 'large' }}
        >
          <Box style={{ display: 'none' }}>
            <Field
              type="hidden"
              name={`packages.${index}.dimensions.width_value`}
            />
            <Field
              type="hidden"
              name={`packages.${index}.dimensions.height_value`}
            />
            <Field
              type="hidden"
              name={`packages.${index}.dimensions.length_value`}
            />
            <Field type="hidden" name={`packages.${index}.weight.value`} />
          </Box>

          <Box>
            {isStandard && (
              <Text style={{ fontStyle: 'italic' }}>
                This is the {STANDARD_PACKAGE_DIMENSIONS.width_value}&rdquo; x{' '}
                {STANDARD_PACKAGE_DIMENSIONS.height_value}&rdquo; x{' '}
                {STANDARD_PACKAGE_DIMENSIONS.length_value}&rdquo; box that your
                products arrived in.
              </Text>
            )}
            <Heading level={5} margin="small">
              Included in box:
            </Heading>
            {packageItems.map((x, i) => {
              return (
                <InputPackageOrderReturnItem
                  key={`${x.id}-${i}`}
                  {...x}
                  index={index}
                  variantId={x.id}
                  value={value.included[x.id]}
                  quantity={x.quantity}
                  onChange={(val) => {
                    onIncludedItemChange(x.id, val, index);
                  }}
                />
              );
            })}
          </Box>
          {get('included', get(field.name, errors)) && (
            <Text color="status-error">
              {get('included', get(field.name, errors))}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

InputOrderReturnPackage.propTypes = {
  index: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  packageItems: PropTypes.array.isRequired,
  onIncludedItemChange: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default InputOrderReturnPackage;
