import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from 'grommet';
import { useStoryblokState } from '../../lib/storyblok';

import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';

const OrderReturnPolicy = ({ ...rest }) => {
  const { storyblokEntry } = useStaticQuery(graphql`
    query OrderReturnShippingLabelPage {
      storyblokEntry(slug: { eq: "create-shipping-label" }) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
    }
  `);
  const story = useStoryblokState(storyblokEntry);
  const components = usePageBodyComponents(story, { pad: 'none' });

  return <Box {...rest}>{components}</Box>;
};

export default OrderReturnPolicy;
