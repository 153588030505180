import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  keys,
  map,
  sum,
  flatten,
  isEqual,
  reduce,
  filter,
  find,
  values,
  omit,
  omitBy,
} from '../../lib/nodash';
import { Box, Button, Text } from 'grommet';
import { Formik, FieldArray, Field } from 'formik';
import { FormNextLink, Add } from 'grommet-icons';
import * as Yup from 'yup';

import InputOrderReturnPackage from './InputOrderReturnPackage';
import OrderReturnPackageInfo from './OrderReturnPackageInfo';
import Loading from '../Loading';
import {
  getLineItemByVariantId,
  calculateInitialReturnItemsPackages,
} from '../../lib/orderReturns';
import { STANDARD_PACKAGE_DIMENSIONS } from '../../lib/constants';

const FormOrderReturnPackages = ({ order, quantityMap, onSubmit, loading }) => {
  const packageItems = useMemo(() => {
    return map(
      (variantId) => {
        const lineItem = getLineItemByVariantId(variantId, order);
        return {
          title: `${get('product.title', lineItem)} ${get(
            'variant.title',
            lineItem
          )}`,
          image: get('image', lineItem),
          weight: get('variant.weight', lineItem),
          weightUnit: get('variant.weightUnit', lineItem),
          id: variantId,
          quantity: quantityMap[variantId],
        };
      },
      filter((x) => quantityMap[x] > 0, keys(quantityMap))
    );
  }, [order, quantityMap]);

  const totalPackageItems = sum(map('quantity', packageItems));

  const validationSchema = Yup.object().shape({
    packages: Yup.array()
      .ensure()
      .of(
        Yup.object().shape({
          included: Yup.object().test(
            'has-items',
            'All boxes must contain at least one return item. Please include at least one return item in this package, or remove it.',
            async (value) => {
              return sum(values(value)) > 0;
            }
          ),
          dimensions: Yup.object()
            .shape({
              width_value: Yup.number().required('Required'),
              height_value: Yup.number().required('Required'),
              length_value: Yup.number().required('Required'),
              unit: Yup.string().required('Required'),
            })
            .required(),
        })
      )
      .test(
        'includes-all-items',
        'You must include all items for return in your packaging',
        async (value, y) => {
          const filteredQtyMap = omitBy((x) => x === 0, quantityMap);
          const included = flatten(map('included', value));
          const flatMap = reduce(
            (mem, x) => {
              keys(x).forEach((y) => (mem[y] = (mem[y] || 0) + x[y]));
              return mem;
            },
            {},
            included
          );
          const invalidVariants = filter((x) => {
            return flatMap[x] !== quantityMap[x];
          }, keys(flatMap));
          const isValid = isEqual(filteredQtyMap, flatMap);
          const variantNames = map((x) => {
            return `${get(
              'title',
              find({ id: x }, packageItems)
            )} to match the return quantity ${quantityMap[x]}`;
          }, invalidVariants).join(', ');
          if (!isValid) {
            return y.createError({
              path: 'packages',
              message: 'Please adjust the quantities of ${variantNames}',
              params: { invalid: invalidVariants, variantNames },
            });
          }
          return true;
        }
      ),
  });

  return (
    <Formik
      initialValues={{
        packages: calculateInitialReturnItemsPackages(
          order,
          quantityMap,
          packageItems
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const packages = map((p) => {
          return {
            ...omit(['included'], p),
            weight: {
              value: sum(
                map((variantId) => {
                  const quantity = p.included[variantId];
                  const item = getLineItemByVariantId(variantId, order);
                  return get('variant.weight', item) * quantity;
                }, keys(p.included))
              ),
              unit: 'pound',
            },
          };
        }, values.packages);
        onSubmit(packages);
      }}
    >
      {({ values, errors, setFieldValue, isValid, handleSubmit }) => {
        const onIncludedItemChange = (id, qty, packageIndex) => {
          setFieldValue(`packages.${packageIndex}.included`, {
            ...values.packages[packageIndex].included,
            [id]: qty,
          });
        };

        return (
          <form onSubmit={handleSubmit}>
            <Box gap="medium">
              <OrderReturnPackageInfo />
              <Box gap="medium">
                <FieldArray
                  name="packages"
                  render={(arrayHelpers) => (
                    <Box gap="large">
                      {values.packages.map((pack, index) => (
                        <Field
                          component={InputOrderReturnPackage}
                          key={index}
                          name={`packages.${index}`}
                          index={index}
                          arrayHelpers={arrayHelpers}
                          packageItems={packageItems}
                          onIncludedItemChange={onIncludedItemChange}
                        />
                      ))}
                      {totalPackageItems > 1 && (
                        <Button
                          onClick={() =>
                            arrayHelpers.push({
                              dimensions: STANDARD_PACKAGE_DIMENSIONS,
                              included: reduce(
                                (mem, x) => {
                                  mem[x.id] = 0;
                                  return mem;
                                },
                                {},
                                packageItems
                              ),
                            })
                          }
                          label="Add another box"
                          secondary
                          reverse
                          icon={<Add size="small" />}
                          alignSelf="end"
                          size="small"
                        />
                      )}
                      {errors &&
                        errors.packages &&
                        !Array.isArray(errors.packages) && (
                          <Box pad="medium">
                            <Text color="status-error">{errors.packages}</Text>
                          </Box>
                        )}
                    </Box>
                  )}
                />
              </Box>
              <Box>
                <Button
                  icon={loading ? <Loading /> : <FormNextLink />}
                  reverse
                  type="submit"
                  primary
                  label="Get Shipping Labels"
                  disabled={!isValid || loading}
                />
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

FormOrderReturnPackages.propTypes = {
  quantityMap: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default FormOrderReturnPackages;
