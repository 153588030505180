import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { m } from 'framer-motion';

import InputNumberAdjust from '../Inputs/InputNumberAdjust';
import ImgixImage from '../ImgixImage';

const InputPackageOrderReturnItem = ({
  title,
  image,
  onChange,
  variantId,
  quantity,
  value,
}) => {
  const id = variantId;
  return (
    <m.div
      animate={{
        opacity: 1,
      }}
    >
      <Box direction="row" align="center" gap="small">
        <Box flex={false}>
          <InputNumberAdjust
            quantity={value}
            max={quantity}
            onChange={onChange}
          />
        </Box>
        <Box as="label" htmlFor={id}>
          <Box
            direction="row"
            align="center"
            gap="small"
            style={{ cursor: 'pointer' }}
          >
            {image && (
              <Box flex={{ shrink: 0 }}>
                <ImgixImage
                  src={image}
                  options={{ w: 40, h: 40, fit: 'crop' }}
                />
              </Box>
            )}
            <Box>
              <Text truncate title={title}>
                {title}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </m.div>
  );
};

InputPackageOrderReturnItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  selected: PropTypes.bool,
  included: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  variantId: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  value: PropTypes.number,
};

export default InputPackageOrderReturnItem;
